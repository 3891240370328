<template>
  <div class="about">
    <GModule>
      <GTitle name="文件夹">
        <a-dropdown class="projectSelect">
          <template #overlay>
            <a-menu>
              <!-- <a-menu-item @click="setProId(0)">
                <span>全部</span>
              </a-menu-item> -->
              <a-menu-item v-for="item in projectList" @click="setProId(item.id)">
                <span v-text="item.name"></span>
              </a-menu-item>
            </a-menu>
          </template>
          <div class="ant-dropdown-link" @click.prevent>
            <span v-text="'选择项目：'+onProject.name||''"></span>
          </div>
        </a-dropdown>
      </GTitle>
      <a-row :gutter="[20]">
        <a-col :span="12">
		  <div class="head">全部</div>
          <a-table class="ttable" :pagination="false" :columns="columnsHe" :data-source="data" :scroll="{ x: 500 }" bordered  >
            <template #bodyCell="{ column ,record}">
              <template v-if="column.key === 'operation'">
				  <div class="caozuo">
					  <a :href="record.file" target="_blank">下载</a>
				  </div>
			  </template>
            </template>
          </a-table>
          <div class="documentFt" v-if="total > 0">
            <a-pagination size="small" :pageSize="limit" :total="total" :current="page" @change="pageChange"/>
          </div>
        </a-col>
        <a-col :span="12">
		  <div class="head">我上传的</div>
          <a-table class="ttable" :pagination="false"  :columns="columnsMy" :data-source="dataMy" :scroll="{ x: 500 }" bordered  >
            <template #bodyCell="{ column,record,index }">
				<template v-if="column.title === '可见人'">
					{{record.groupNameArr}}
				</template>
              <template v-if="column.key === 'operation'">
				  <div class="caozuo">
					  <a :href="record.file" target="_blank">下载</a>
					  <a-popconfirm
					      title="确认是否删除?"
					      ok-text="确认"
					      cancel-text="取消"
					      @confirm="delClick(record.id,index)">
					      <p>删除</p>
					  </a-popconfirm>
				  </div>
			  </template>
            </template>
          </a-table>
          <div class="documentFt">
            <a-pagination size="small" :pageSize="limit1" :total="total1" :current="page1" @change="pageChange1" v-if="total1 > 0"/>
            <a-button @click="open()">上传</a-button>
          </div>
        </a-col>
      </a-row>
	  <a-modal v-model:visible="isOpen" title="上传文档" @ok="ok()" @cancel="no()">
	  	<div class="first1">
	  		<p>文档</p>
	  		<input
	  			type="file" 
	  			ref="inputer" 
	  			@change="handleFileChange" />
	  	</div>
	  	<div class="first1" style="margin-top: 15px;">
	  		<p>查看权限</p>
	  		<div>
				<a-checkbox 
					:checked="item.isCheck" 
					v-for="(item,index) in groupArr" 
					:key="index"
					@click="changeClick(index)">
					{{index == 0 ? '所有人' : index == 1 ? '仅自己可见' : item.name}}
				</a-checkbox>
			</div>
	  	</div>
	  </a-modal>
    </GModule>
  </div>
</template>
<script>
export default {
  name: 'Document',
  data(){
    return {
      // onIndex: 0,
      // navList:[],
      projectList:[],
      all:1,
      data: [],
      columnsHe: [{
          title: '文件名称',
          width: 180,
          dataIndex: 'name',
          fixed: 'left'
        }, {
          title: '创建人',
          width: 80,
          dataIndex: 'create_name'
        }, {
          title: '更新时间',
          width: 80,
          dataIndex: 'createtime_text'
        }, {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 60,
      }],
      columnsMy: [{
          title: '文件名称',
          width: 180,
          dataIndex: 'name',
          fixed: 'left'
        }, {
          title: '更新时间',
          width: 80,
          dataIndex: 'createtime_text'
        }, {
          title: '可见人',
          width: 100,
          dataIndex: 'group_name_arr'
        }, {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 60,
      }],
	  dataMy:[],
	  limit:10,
	  page:1,
	  total:0 ,// 总条数
	  limit1:10,
	  page1:1,
	  total1:0 ,// 总条数
	  isOpen:false,
	  fileName:'' ,// 文档名称
	  fileType:'' ,// 文档类型
	  groupArr:null ,// 权限集合
	  fileUrl:'' // 文档上传后地址
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    proId() {
      return this.$store.state.proId
    },
    onProject(){
      var pro = this.projectList.find(item=>item.id==this.proId)
      return !this.all&&pro?pro:{name:'全部'}
    }
  },
  created(){
    if(this.proId)this.all=0;
    this.getProject()
	// 初始化文档列表
	this.getAllList()
	this.getMyList()
  },
  methods:{
    async getProject(){
      var project = await this.$utils.api.get({
        url:'/project/my_selectpage',
        data:{},
      })
      if((!this.$store.state.proId)&&project.list.length){
        this.$store.commit('setStore', {
          name:'proId', 
          data:project.list[0].id
        })
      }
      this.projectList = project.list;
      console.log('project ',project);
      // /project/my_selectpage
    },
    setProId(id){
      if(!id){
        this.all = 1;
		this.$store.commit('setStore', {
		  name: 'proId', 
		  data: 0
		})
      }else{
        this.all = 0;
        this.$store.commit('setStore', {
          name: 'proId', 
          data: id
        })
      }
	  this.page = 1
	  this.data = []
	  this.total = 0
	  this.getAllList()
	  this.page1 = 1
	  this.dataMy = []
	  this.total1 = 0
	  this.getMyList()
    },
	async getAllList(){
		// 获取全部文档
		let res = await this.$utils.api.post({
			url:'/document/index',
			data:{
				limit:this.limit,
				page:this.page
			},
			result:1
		})
		if(res.code == 1){
			this.data = res.data.rows
			this.total = res.data.total
		}
	},
	pageChange(page,pageSize){
		// 切换页码时查询列表
		this.page = page
		this.getAllList()
	},
	async getMyList(){
		// 获取我的文档
		let res = await this.$utils.api.post({
			url:'/document/my_index',
			data:{
				limit:this.limit1,
				page:this.page1
			},
			result:1
		})
		if(res.code == 1){
			for(let i in res.data.rows){
				if(res.data.rows[i].group_name_arr.length > 0){
					res.data.rows[i].groupNameArr = res.data.rows[i].group_name_arr.join(',')
				}
			}
			this.dataMy = res.data.rows
			this.total1 = res.data.total
		}
	},
	pageChange1(page,pageSize){
		// 切换页码时查询列表
		this.page1 = page
		this.getMyList()
	},
	async open(){
		// 弹出上传
		this.isOpen = true
		// 重置数据
		this.fileName = ''
		this.fileType = ''
		this.fileUrl = ''
		//获取权限
		let res = await this.$utils.api.post({
			url:'/document/setting',
			result:1
		})
		if(res.code == 1){
			let arr = []
			for(let i in res.data.typeList){
				arr.push({id:null,type:i,name:res.data.typeList[i],isCheck:false})
			}
			let r = await this.$utils.api.post({
				url:'/team/group_selectpage',
				result:1
			})
			if(r.code == 1){
				for(let i in r.data.list){
					arr.push({id:r.data.list[i].id,type:'public',name:r.data.list[i].name,isCheck:false})
				}
			}
			this.groupArr = arr
		}
	},
	async handleFileChange(){
		// 监听文件
		let that = this
		let inputDOM = this.$refs.inputer;
		let file = inputDOM.files[0];// 通过DOM取文件数据
		this.fileName = file.name
		// 上传文件
		let res = await this.$utils.api.upLoad(file,'document')
		if(res.code == 1){
			setTimeout(()=>{
				that.fileUrl = res.data.fullurl // 上传后地址
			},300)
		}
	},
	changeClick(index){
		if(index == 0 ||  index == 1){
			// 单选所有人、仅自己
			for(let i in this.groupArr){
				if(i == index){
					this.groupArr[i].isCheck = !this.groupArr[i].isCheck
					if(this.groupArr[i].isCheck){
						this.fileType = this.groupArr[i].type
					}else{
						this.fileType = ''
					}
				}else{
					this.groupArr[i].isCheck = false
				}
			}
		}else{
			// 多选其他
			this.groupArr[0].isCheck = false
			this.groupArr[1].isCheck = false
			for(let i in this.groupArr){
				if(i == index){
					this.groupArr[i].isCheck = !this.groupArr[i].isCheck
					if(this.groupArr[i].isCheck){
						this.fileType = this.groupArr[i].type
					}else{
						this.fileType = ''
					}
				}
			}
		}
	},
	async ok(){
		// 确定
		console.log("this.fileUrl",this.fileUrl)
		if(this.fileUrl == ''){
			this.$utils.msgWar('请上传文档')
			return
		}
		let ids = []
		for(let i in this.groupArr){
			if(this.groupArr[i].isCheck){
				ids.push(this.groupArr[i].id)
			}
		}
		if(ids.length == 0){
			this.$utils.msgWar('请选择权限')
			return
		}
		let row = {
			'row':{
				'name':this.fileName,
				'type':this.fileType,
				'group_ids':ids[0] == null ? null : ids,
				'file':this.fileUrl
			}
		}
		let res = await this.$utils.api.post({
			url:'/document/add',
			data:row,
			result:1
		})
		if(res.code == 1){
			this.$utils.msgSuc('上传成功')
			this.isOpen = false
			// 重置数据
			this.fileName = ''
			this.fileType = ''
			this.fileUrl = ''
			this.$refs.inputer.value =''
			this.page = 1
			this.data = []
			this.total = 0
			this.getAllList()
			this.page1 = 1
			this.dataMy = []
			this.total1 = 0
			this.getMyList()
		}
	},
	no(){
		// 取消
		// 重置数据
		this.$refs.inputer.value =''
		this.fileName = ''
		this.fileType = ''
		this.fileUrl = ''
	},
	async delClick(id,index){
		// 删除
		let res = await this.$utils.api.post({
			url:'/document/del',
			data:{
				ids:id
			},
			result:1
		})
		if(res.code == 1){
			this.$utils.msgSuc('删除成功')
			this.dataMy.splice(index,1)
			if(this.dataMy.length == 0){
				this.total1 = 0
			}
		}
	}
  }
};
</script>

<style lang="scss">
.about{
  padding:20px;
  .stretch{
    display: flex;
    justify-content: space-between;
  }
  .projectSelect{
    line-height:30px;
  }
  .ant-dropdown-link{
    min-width:180px;
    overflow: hidden;
    span:after{
      margin-left:20px;
      content:'';
      display: inline-block;;
      border-top: 6px solid #aaa;
      border-left: transparent 6px solid;
      border-right: transparent 6px solid;
      position: relative;
      top:12px;
      float: right;
    }
  }
  .f-mr20{
    margin-right:20px;
  }
  .ttbox{
    flex:1;
  }
  .documentFt{
    margin-top:20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .first1{
  	display: flex;
  	padding: 30px 20px 0 20px;
  	p{
  		padding: 0;
  		width: 100px;
  		text-align: center;
		margin-top: 20px;
  	}
  }
  .caozuo{
  	display: flex;
  	justify-content: space-around;
  	align-items: center;
  	p,a{
  		margin: 0;
  		font-size: 12px;
  		color: #407cff;
  	}
  }
}
.head{
	height: 40px;
	line-height: 40px;
	background-color: #f4f4f4;
	text-align: center;
}
</style>